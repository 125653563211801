<template>
    <div class="h-100-cust px-5 py-4">
        <Loader v-if="loading" />
        <b-row>
            <b-col xl="12" md="12">
                <b-row align-h="around">
                    <b-col cols="6">
                        <b-row align-h="around">
                            <span class="entity-title">{{ getEntityTitle() }}</span>
                        </b-row>
                    </b-col>
                    <b-col cols="6">
                        <b-row class="mb-3" v-for="(entity, idx) in entity_list" :key="idx">
                            <b-col cols="8">
                                <b-form-input :id="entity._id" :value="getEntityName(entity)" autofocus
                                    :placeholder="getInputPlaceholder()"
                                    aria-describedby="project-name-help project-name-feedback"
                                    :state="null"
                                    @keydown.enter="updateEntity(idx, entity, $event)"
                                    @focusout="updateEntity(idx, entity, $event)"
                                     />
                                <!-- <b-form-invalid-feedback id="project-name-feedback">
                                    Min. 5 and Max. 50 chracters, numbers, spaces and dots are allowed.
                                </b-form-invalid-feedback>
                                <b-form-valid-feedback id="project-name-help">
                                    Looks good!
                                </b-form-valid-feedback> -->
                            </b-col>
                            <b-col cols="2">
                                <button :id="idx" class="mb-0 btn h1" @click="deleteEntity(entity._id, idx)">
                                    <b-icon icon="trash" variant="dark"></b-icon>
                                </button>
                                <!-- <b-tooltip
                                    :trigger="'hover'"
                                    :target="idx"
                                    placement="bottom"
                                    variant="info"
                                >
                                    Delete Entity
                                </b-tooltip> -->
                            </b-col>
                            <b-col v-if="idx + 1 == entity_list.length" cols="2">
                                <button id="addEntity" class="mb-0 btn h1" @click="addEntity()">
                                    <b-icon icon="plus-circle" variant="dark"></b-icon>
                                </button>
                                <b-tooltip :trigger="'hover'" target="addEntity" placement="bottom" variant="info">
                                    Add Entity
                                </b-tooltip>
                            </b-col>
                        </b-row>
                        <b-row class="mb-3" v-if="entity_list.length == 0">
                            <button id="addEntity" class="mb-0 btn h1" @click="addEntity()">
                                <b-icon icon="plus-circle" variant="dark"></b-icon>
                            </button>
                            <b-tooltip :trigger="'hover'" target="addEntity" placement="bottom" variant="info">
                                Add Entity
                            </b-tooltip>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// import axios from "../utility/axios";
import Loader from "./Loader";
import json from '../data/entityConfig.json'
// import Swal from "sweetalert2";

export default {
    name: "MainEntity",
    props: {
        entityType: {
            type: String
        }
    },
    components: {
        Loader
    },
    data() {
        return {
            loading: false,
            entity_list: [],
            entityTypeDataDict: json
        };
    },
    async created() {
        await this.loadEntity();
        this.getEntityList();
    },
    computed: {
        axiosParamsList() {
            const params = new URLSearchParams();
            if (this.$store.getters.projectId)
                params.append('project_id', this.$store.getters.projectId);
            return params;
        }
    },
    methods: {
        loadEntity() {
            this.urlList = this.entityTypeDataDict[this.entityType]["urlList"];
            this.urlPost = this.entityTypeDataDict[this.entityType]["urlPost"];
            this.urlDelete = this.entityTypeDataDict[this.entityType]["urlDelete"];
            this.urlParams = this.entityTypeDataDict[this.entityType]["urlParams"];
            this.entity_id_key = this.entityTypeDataDict[this.entityType]["entity_id_key"];
            this.entity_id_name = this.entityTypeDataDict[this.entityType]["entity_id_name"];
        },
        axiosParamsDelete(entity_id) {
            const params = new URLSearchParams();
            if (this.$store.getters.projectId)
                params.append('project_id', this.$store.getters.projectId);
            if (entity_id)
                params.append(this.entity_id_key, entity_id);
            return params;
        },
        pushAddEntityItem() {
            let pushDict = {};
            pushDict[this.entity_id_name] = "";
            this.entity_list.push(pushDict);
            // this.entity_list.push({ "sc_entity_name": "" });
        },
        setFocus() {
            document.getElementById((this.entity_list.length - 1).toString()).focus();
        },
        getEntityTitle() {
            return this.entityTypeDataDict[this.entityType]["title"];
        },
        getInputPlaceholder() {
            return this.entityTypeDataDict[this.entityType]["inputPlaceholder"];
        },
        getEntityName(entity) {
            return entity[this.entity_id_name];
        },
        isEntityExists(entity_name) {
            for (let entity of this.entity_list) {
                if (entity[this.entity_id_name] === entity_name) {
                    return true;
                }
            }
            return false;
        },
        async addEntity() {
            if (this.entity_list.length == 0) {
                await this.pushAddEntityItem();
                this.setFocus();
            } else {
                if (this.entity_list[this.entity_list.length - 1]["_id"] == null) {
                    return;
                } else {
                    await this.pushAddEntityItem();
                    this.setFocus();
                }
            }
        },
        updateEntity(idx, entity, event) {
            console.log(entity)
            this.loading = true;

            let entity_name = event.target.value;
            entity_name = entity_name.trim();
            event.target.value = entity_name;

            if (entity[this.entity_id_name] === entity_name || entity_name.length < 1) {
                this.loading = false;
                return;
            } else {

                if (this.isEntityExists(entity_name)) {
                    this.$toasted.error("Entity name already exists !!");
                    this.loading = false;
                    return;
                }

                let requestBody = {};
                let _id = this.entity_list[idx]._id;
                // let entity_name = entity_name;

                if (_id != undefined) {
                    requestBody[this.entity_id_name] = entity_name;
                    requestBody.project_id = this.$store.getters.projectId;
                    const promise = this.axios.post(this.urlPost + "?" + this.urlParams + "=" + _id, requestBody);
                    promise.then(() => {
                        entity[this.entity_id_name] = entity_name;
                        this.$toasted.success("Entity successfully Edited !!");
                    })
                        .catch(error => {
                            console.log("Error: " + error);
                            this.$toasted.error("Please try again later !!");
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    requestBody[this.entity_id_name] = entity_name;
                    requestBody.project_id = this.$store.getters.projectId;
                    const promise = this.axios.post(this.urlPost, requestBody);
                    promise.then((response) => {
                        entity._id = response.data.result._id;
                        entity[this.entity_id_name] = response.data.result[this.entity_id_name];
                        this.$toasted.success("Entity successfully Added !!");
                    })
                        .catch(error => {
                            console.log("Error: " + error);
                            this.$toasted.error("Please try again later !!");
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            }
        },
        deleteEntity(entity_id, idx) {
            this.loading = true;
            let requestBody = {};
            requestBody["project_id"] = this.$store.getters.projectId;
            requestBody[this.entity_id_key] = entity_id;
            this.axios
                .delete(this.urlDelete, {
                    data: requestBody
                })
                .then((res) => {
                    res;
                    this.entity_list.splice(idx, 1);
                    this.loading = false;
                    this.$toasted.success("Entity successfully Deleted !!");
                })
                .catch((error) => {
                    console.log("Error: " + error);
                    this.loading = false;
                    this.$toasted.error("Please try again later !!");
                });
        },
        getEntityList() {
            this.loading = true;
            this.axios
                .get(this.urlList, {
                    params: this.axiosParamsList
                })
                .then((res) => {
                    this.entity_list = res.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("Error: " + error);
                    this.loading = false;
                });
        },
    }
};
</script>

<style scoped lang="scss">
.h-100-cust {
    height: auto;
}

.entity-title {
    text-align: center;
}
</style>