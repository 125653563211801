<template>
    <div>
        <main-entity
            entityType="supply_chain_entity"
        />
        <main-entity
            entityType="product"
        />
        <main-entity
            entityType="department"
        />
        <main-entity
            entityType="org_boundary"
        />

        <router-link to="projects">
            <button id="homeButton" class="mb-0 btn h1 float float-1">
                <img class="home" src="../assets/img/home.svg" />
            </button>
            <b-tooltip
                :trigger="'hover'"
                target="homeButton"
                placement="bottom"
                variant="info"
            >
                Home
            </b-tooltip>
        </router-link>

        <button id="backButton" class="mb-0 btn h1 float float-2" @click="backToProject()">
            <b-icon icon="arrow-left-circle" variant="dark" viewBox="4 4 8 8"></b-icon>
        </button>
        <b-tooltip
            :trigger="'hover'"
            target="backButton"
            placement="bottom"
            variant="info"
        >
            Back
        </b-tooltip>

    </div>
</template>

<script>
import MainEntity from "../components/MainEntity";
// import Swal from "sweetalert2";

export default {
    components: {
        MainEntity
    },
    data() {
        return {
        };
    },
    methods: {
        backToProject() {
            this.$router.push("/table")
        }
    }
}
</script>

<style scoped lang="scss">

.float {
    position: fixed;
    // bottom: 70px;
    right: 20px;
    margin: 0px;
}
.float-1 {
    bottom: 150px;
    height: 56.25px;
    width: 75px;
}
.float-2 {
    bottom: 70px;
    height: 56.25px;
    width: 75px;
}
.home {
    width: 100%;
}

</style>